import {
  AddUserGroupRequest,
  GetUserGroupsRequest,
  GetUserGroupsResponse,
  GetUsersRequest,
  GetUsersResponse,
  User,
  UserGroup,
} from '@ulysses-inc/harami_api_client'
import { ActionTypes, GetUsersFilter } from './types'

export const getUsers = (request: GetUsersRequest, filter?: GetUsersFilter) => {
  return {
    type: ActionTypes.REQUEST_GET_USERS,
    isLoading: true,
    request,
    filter,
  }
}

const getSuccessUsers = ({ users, count }: GetUsersResponse) => {
  return {
    type: ActionTypes.SUCCESS_GET_USERS,
    isLoading: false,
    users,
    count,
  }
}

const getErrorUsers = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_USERS,
    isLoading: false,
    error,
  }
}

const changeUsersPage = (
  page: number,
  pageSize?: number,
  filter?: GetUsersFilter,
) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_USERS_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
    filter,
  }
}

const changeUsersSize = (pageSize: number, filter?: GetUsersFilter) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_USERS_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
    filter,
  }
}

const updatePagination = (limit: number, offset: number) => {
  return {
    type: ActionTypes.UPDATE_PAGINATION,
    request: {
      limit,
      offset,
    },
  }
}

export const addUser = (user: User) => {
  return {
    type: ActionTypes.REQUEST_ADD_USER,
    isLoading: true,
    user,
  }
}

const addSuccessUser = (user: User) => {
  return {
    type: ActionTypes.SUCCESS_ADD_USER,
    isLoading: false,
    user,
  }
}

const addErrorUser = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_USER,
    isLoading: false,
    error,
  }
}

export const updateUser = (
  userId: string,
  user: User,
  currentPassword?: string,
) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_USER,
    isLoading: true,
    userId,
    user,
    currentPassword,
  }
}

const updateSuccessUser = (user: User) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_USER,
    isLoading: false,
    user,
  }
}

const updateErrorUser = (error: Error) => {
  return {
    type: ActionTypes.ERROR_UPDATE_USER,
    isLoading: false,
    error,
  }
}

const deleteUser = (userId: string) => {
  return {
    type: ActionTypes.REQUEST_DELETE_USER,
    isLoading: true,
    userId,
  }
}

const deleteSuccessUser = (userId: string) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_USER,
    isLoading: false,
    userId,
  }
}

const deleteErrorUser = (error: Error) => {
  return {
    type: ActionTypes.ERROR_DELETE_USER,
    isLoading: false,
    error,
  }
}

const validateDeleteUser = (userUUID: string) => {
  return {
    type: ActionTypes.REQUEST_VALIDATE_DELETE_USER,
    userUUID,
  }
}

const validateDeleteUserSuccess = () => {
  return {
    type: ActionTypes.SUCCESS_VALIDATE_DELETE_USER,
  }
}

const userDeleteValidateErrorMessage = (
  userDeleteValidateErrorMessage: string,
) => {
  return {
    type: ActionTypes.USER_DELETE_VALIDATE_ERROR_MESSAGE,
    userDeleteValidateErrorMessage,
  }
}

const resetValidateDeleteUser = () => {
  return {
    type: ActionTypes.RESET_VALIDATE_DELETE_USER,
  }
}

const validateDeleteUserError = (error: Error) => {
  return {
    type: ActionTypes.ERROR_VALIDATE_DELETE_USER,
    error,
  }
}

export const changeIsShowEditUserDrawer = (isShowEditUserDrawer: boolean) => {
  return {
    type: ActionTypes.CHANGE_IS_SHOW_EDIT_USER_DRAWER,
    isShowEditUserDrawer,
  }
}

export const changeUpdatedUsersFlag = (updatedUsers: boolean) => {
  return {
    type: ActionTypes.CHANGE_UPDATED_USERS_FLAG,
    updatedUsers,
  }
}

const updateSegmentIndex = (index: number) => {
  return {
    type: ActionTypes.UPDATE_SEGMENT_INDEX,
    segmentIndex: index,
  }
}

const updateActiveUserId = (activeUserId: string) => {
  return {
    type: ActionTypes.UPDATE_ACTIVE_USER_ID,
    activeUserId,
  }
}

export type UsersAction =
  | ReturnType<typeof getUsers>
  | ReturnType<typeof getSuccessUsers>
  | ReturnType<typeof getErrorUsers>
  | ReturnType<typeof changeUsersPage>
  | ReturnType<typeof changeUsersSize>
  | ReturnType<typeof updatePagination>
  | ReturnType<typeof addUser>
  | ReturnType<typeof addSuccessUser>
  | ReturnType<typeof addErrorUser>
  | ReturnType<typeof updateUser>
  | ReturnType<typeof updateSuccessUser>
  | ReturnType<typeof updateErrorUser>
  | ReturnType<typeof deleteUser>
  | ReturnType<typeof deleteSuccessUser>
  | ReturnType<typeof deleteErrorUser>
  | ReturnType<typeof validateDeleteUser>
  | ReturnType<typeof validateDeleteUserSuccess>
  | ReturnType<typeof userDeleteValidateErrorMessage>
  | ReturnType<typeof validateDeleteUserError>
  | ReturnType<typeof resetValidateDeleteUser>
  | ReturnType<typeof changeIsShowEditUserDrawer>
  | ReturnType<typeof changeUpdatedUsersFlag>
  | ReturnType<typeof updateSegmentIndex>
  | ReturnType<typeof updateActiveUserId>

export const getUserGroups = (request: GetUserGroupsRequest) => {
  return {
    type: ActionTypes.REQUEST_GET_USER_GROUPS,
    isLoading: true,
    request,
  }
}

const getSuccessUserGroups = ({ userGroups, count }: GetUserGroupsResponse) => {
  return {
    type: ActionTypes.SUCCESS_GET_USER_GROUPS,
    isLoading: false,
    userGroups,
    count,
  }
}

const getErrorUserGroups = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_USER_GROUPS,
    isLoading: false,
    error,
  }
}

const changeUserGroupsPage = (page: number, pageSize?: number) => {
  const offset = pageSize ? (page - 1) * pageSize : 0
  return {
    type: ActionTypes.REQUEST_CHANGE_USER_GROUPS_PAGE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset,
    },
  }
}

const changeUserGroupsSize = (pageSize: number) => {
  return {
    type: ActionTypes.REQUEST_CHANGE_USER_GROUPS_SIZE,
    isLoading: true,
    request: {
      limit: pageSize,
      offset: 0,
    },
  }
}

const addUserGroup = (request: AddUserGroupRequest) => {
  return {
    type: ActionTypes.REQUEST_ADD_USER_GROUP,
    isLoading: true,
    request,
  }
}

const addSuccessUserGroup = (userGroup: UserGroup) => {
  return {
    type: ActionTypes.SUCCESS_ADD_USER_GROUP,
    isLoading: false,
    userGroup,
  }
}

const addErrorUserGroup = (error: Error) => {
  return {
    type: ActionTypes.ERROR_ADD_USER_GROUP,
    isLoading: false,
    error,
  }
}

const updateUserGroup = (userGroupId: number, userGroup: UserGroup) => {
  return {
    type: ActionTypes.REQUEST_UPDATE_USER_GROUP,
    isLoading: true,
    userGroupId,
    userGroup,
  }
}

const updateSuccessUserGroup = () => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_USER_GROUP,
    isLoading: false,
    userGroup: undefined,
  }
}

const updateErrorUserGroup = (error: Error) => {
  return {
    type: ActionTypes.SUCCESS_UPDATE_USER_GROUP,
    isLoading: false,
    error,
  }
}

const deleteUserGroup = (userGroupId: number) => {
  return {
    type: ActionTypes.REQUEST_DELETE_USER_GROUP,
    isLoading: true,
    userGroupId,
  }
}

const deleteSuccessUserGroup = (userGroupId: number) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_USER_GROUP,
    isLoading: false,
    userGroupId,
  }
}

const deleteErrorUserGroup = (error: Error) => {
  return {
    type: ActionTypes.SUCCESS_DELETE_USER_GROUP,
    isLoading: false,
    error,
  }
}

const validateDeleteUserGroup = (userGroupId: number) => {
  return {
    type: ActionTypes.REQUEST_VALIDATE_DELETE_USER_GROUP,
    userGroupId,
  }
}

const validateDeleteUserGroupSuccess = () => {
  return {
    type: ActionTypes.SUCCESS_VALIDATE_DELETE_USER_GROUP,
  }
}

const userGroupDeleteValidateErrorMessage = (
  userGroupDeleteValidateErrorMessage: string,
) => {
  return {
    type: ActionTypes.USER_GROUP_DELETE_VALIDATE_ERROR_MESSAGE,
    userGroupDeleteValidateErrorMessage,
  }
}

const resetValidateDeleteUserGroup = () => {
  return {
    type: ActionTypes.RESET_VALIDATE_DELETE_USER_GROUP,
  }
}

const validateDeleteUserGroupError = (error: Error) => {
  return {
    type: ActionTypes.ERROR_VALIDATE_DELETE_USER_GROUP,
    error,
  }
}

const changeIsShowEditUserGroupDrawer = (
  isShowEditUserGroupDrawer: boolean,
) => {
  return {
    type: ActionTypes.CHANGE_IS_SHOW_EDIT_USER_GROUP_DRAWER,
    isShowEditUserGroupDrawer,
  }
}

const updateActiveUserGroupId = (activeUserGroupId?: number) => {
  return {
    type: ActionTypes.UPDATE_ACTIVE_USER_GROUP_ID,
    activeUserGroupId: activeUserGroupId,
  }
}

export type UserGroupsAction =
  | ReturnType<typeof getUserGroups>
  | ReturnType<typeof getSuccessUserGroups>
  | ReturnType<typeof getErrorUserGroups>
  | ReturnType<typeof changeUserGroupsPage>
  | ReturnType<typeof changeUserGroupsSize>
  | ReturnType<typeof addUserGroup>
  | ReturnType<typeof addSuccessUserGroup>
  | ReturnType<typeof addErrorUserGroup>
  | ReturnType<typeof updateUserGroup>
  | ReturnType<typeof updateSuccessUserGroup>
  | ReturnType<typeof updateErrorUserGroup>
  | ReturnType<typeof deleteUserGroup>
  | ReturnType<typeof deleteSuccessUserGroup>
  | ReturnType<typeof deleteErrorUserGroup>
  | ReturnType<typeof validateDeleteUserGroup>
  | ReturnType<typeof validateDeleteUserGroupSuccess>
  | ReturnType<typeof userGroupDeleteValidateErrorMessage>
  | ReturnType<typeof validateDeleteUserGroupError>
  | ReturnType<typeof resetValidateDeleteUserGroup>
  | ReturnType<typeof changeIsShowEditUserGroupDrawer>
  | ReturnType<typeof updateActiveUserGroupId>

export const getUser = (userId: string) => {
  return {
    type: ActionTypes.REQUEST_GET_USER,
    isLoading: true,
    userId,
  }
}

const getSuccessUser = (user: User) => {
  return {
    type: ActionTypes.SUCCESS_GET_USER,
    isLoading: false,
    user,
  }
}

const getErrorUser = (error: Error) => {
  return {
    type: ActionTypes.ERROR_GET_USER,
    isLoading: false,
    error,
  }
}

export type UserAction =
  | ReturnType<typeof getUser>
  | ReturnType<typeof getSuccessUser>
  | ReturnType<typeof getErrorUser>

export default {
  getUsers,
  getSuccessUsers,
  getErrorUsers,
  changeUsersPage,
  changeUsersSize,
  updatePagination,
  addUser,
  addSuccessUser,
  addErrorUser,
  updateUser,
  updateSuccessUser,
  updateErrorUser,
  deleteUser,
  deleteSuccessUser,
  deleteErrorUser,
  validateDeleteUser,
  validateDeleteUserSuccess,
  userDeleteValidateErrorMessage,
  validateDeleteUserError,
  resetValidateDeleteUser,
  changeIsShowEditUserDrawer,
  changeUpdatedUsersFlag,
  updateSegmentIndex,
  updateActiveUserId,
  getUserGroups,
  changeUserGroupsPage,
  changeUserGroupsSize,
  getSuccessUserGroups,
  getErrorUserGroups,
  addUserGroup,
  addSuccessUserGroup,
  addErrorUserGroup,
  updateUserGroup,
  updateSuccessUserGroup,
  updateErrorUserGroup,
  deleteUserGroup,
  deleteSuccessUserGroup,
  deleteErrorUserGroup,
  validateDeleteUserGroup,
  validateDeleteUserGroupSuccess,
  userGroupDeleteValidateErrorMessage,
  validateDeleteUserGroupError,
  resetValidateDeleteUserGroup,
  changeIsShowEditUserGroupDrawer,
  updateActiveUserGroupId,
  getUser,
  getSuccessUser,
  getErrorUser,
}
