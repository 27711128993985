import * as Sentry from '@sentry/browser'
import { AuthApi, LogoutResponse } from '@ulysses-inc/harami_api_client'
import { useDispatch } from 'react-redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { clearAllStateAction } from 'src/state/store'

export const useAuthProcess = () => {
  const dispatch = useDispatch()

  const cleanupAndPushOutUser = async (
    { serverRequest } = { serverRequest: false },
  ) => {
    let res: LogoutResponse | undefined

    if (serverRequest) {
      try {
        res = await baseClient.getApi(AuthApi).authLogout()
      } catch (e) {
        Sentry.captureException(
          new Error('ログアウト処理に失敗しました', { cause: e }),
        )
      }
    }

    // NOTE: 古いコードによって持っている可能性があるキー（localStorageKeysに定義がない）も削除しておく
    // 利用されてないキーなので値が残っていても特に実害は無い（いつでもこの削除処理をやめて良い）
    localStorage.removeItem('loginCompany')
    localStorage.removeItem('loginId')
    localStorage.removeItem('loginPlaceNodes')
    localStorage.removeItem('loginUserId')

    localStorage.removeItem(localStorageKeys.loginUserLoginId)
    localStorage.removeItem(localStorageKeys.accessToken)
    localStorage.removeItem(localStorageKeys.loginUserUuid)
    localStorage.removeItem(localStorageKeys.loginUserName)
    localStorage.removeItem(localStorageKeys.loginUserRole)
    localStorage.removeItem(localStorageKeys.loginCompanyId)
    localStorage.removeItem(localStorageKeys.loginCompanyName)
    localStorage.removeItem(localStorageKeys.loginCompanyContractPlan)
    localStorage.removeItem(localStorageKeys.loginUserPlaceNodes)
    localStorage.removeItem(localStorageKeys.listFilter)
    localStorage.removeItem(localStorageKeys.sortOrderTemplates)
    localStorage.removeItem(localStorageKeys.genbaDxAwardBanner)
    dispatch(clearAllStateAction())

    location.href =
      res?.location === undefined || res?.location === ''
        ? '/login'
        : res?.location
  }

  return { cleanupAndPushOutUser }
}
