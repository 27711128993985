import { Document, Font, Page, StyleSheet, Text } from '@react-pdf/renderer'
import {
  ApprovalFlow,
  ReportNodeSchema,
  ReportPageSchema,
  ReportStatusEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import nasuBold from 'src/assets/fonts/NasuFont/Nasu-Bold.ttf'
import nasuRegular from 'src/assets/fonts/NasuFont/Nasu-Regular.ttf'
import getCalculateScorePercentage from 'src/exShared/hooks/reports/getCalculateScorePercentage'
import { ReportResultSchema } from 'src/exShared/types/types'
import { getDescendantNodes } from 'src/exShared/util/nodes/getDescendantNodes'
import { isInvalid } from 'src/exShared/util/report/isInvalid'
import { isDefined } from 'src/util/idDefined'
import BreakText from '../BreakText'
import Col from '../Col'
import Grid from '../Grid'
import DeviatedAnswers from '../deviatedAnswers/DeviatedAnswers'
import ReportSummaryPage from '../reportSummaryPage/ReportSummaryPage'
import Scoring from '../scoring/Scoring'
import Approvals from './Approvals'
import Overview from './Overview'
import ReportSummaryOptions from './reportOptions/ReportSummaryOptions'

interface OwnProps {
  report: ReportResultSchema
  comment: string
}

interface MergeProps {
  getPages: () => Array<ReportPageSchema>
  getDeviatedAnswers: () => ReportNodeSchema[]
}

type ReportSummaryDocumentProps = OwnProps & MergeProps

const ReportSummaryDocumentContainer: React.FC<OwnProps> = (
  _props: OwnProps,
) => {
  const mergeProps: MergeProps = {
    getPages: () =>
      _props.report.pageIds
        .map((id: number) => _props.report.pages[id])
        .filter(isDefined),

    // Object.valuesはObjectのkeyがuint32の上限を超えると昇順が保証されなくなるため、ReportNodeIdの昇順でソートする
    // Notion: https://www.notion.so/harami_web-20231218-cb708f3ce48d482f9dc9123889fcf272
    getDeviatedAnswers: (): ReportNodeSchema[] => {
      return Object.values(_props.report.nodes)
        .filter((node: ReportNodeSchema) => {
          if (node.type !== TemplateNodeTypeEnum.Question) {
            return false
          }
          return isInvalid(node.question)
        })
        .sort((a, b) => a.id - b.id)
    },
  }
  const props = { ..._props, ...mergeProps }
  return <ReportSummaryDocument {...props} />
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Nasu',
    fontSize: 10,
  },
  scoringTitle: {
    fontSize: 14,
  },
  textPadding: {
    padding: 5,
  },
})

Font.registerHyphenationCallback(word => {
  // See https://github.com/Ulysses-inc/harami_client/pull/3079 for details
  return word.split('')
})

// NOTE: 日本語fontがreact-pdfが対応されていないので、日本語fontのNasuFontを利用
Font.register({
  family: 'Nasu',
  fonts: [{ src: nasuRegular }, { src: nasuBold, fontWeight: 'bold' }],
})

const showApprovals = (
  apporovalFlows: ApprovalFlow[],
  status: ReportStatusEnum,
) => {
  if (apporovalFlows.length === 0) {
    return false
  }

  if (
    status === ReportStatusEnum.INCOMPLETE ||
    status === ReportStatusEnum.APPROVAL_REMAND
  ) {
    return false
  }

  return true
}

const ReportSummaryDocument: React.FC<ReportSummaryDocumentProps> = (
  props: ReportSummaryDocumentProps,
) => {
  const deviatedAnswers = props.getDeviatedAnswers()
  const allChildNodes = Object.values(props.report.pages).reduce(
    (prev, cur) => {
      const { dictOfNode: pageChildNodes } = getDescendantNodes(
        cur.nodes ?? [],
        props.report.nodes,
        {
          ignoreRepeatOriginalSection: true,
        },
      )
      return Object.assign(prev, pageChildNodes)
    },
    {},
  )
  const { sumAllTotalScores, sumAllResultScores, calculateScorePercentage } =
    getCalculateScorePercentage(allChildNodes)
  return (
    <Document>
      {props.report !== undefined && (
        <Page size="A4" style={styles.body}>
          <Overview
            {...{
              reportName: props.report.name,
              reportDate: props.report.reportDate,
              schedules: props.report.schedules,
              userName: props.report.assignee?.name ?? '',
              isShowScore: props.report.isShowScore,
              sumAllTotalScores: sumAllTotalScores,
              sumAllResultScores: sumAllResultScores,
              score: calculateScorePercentage,
              status: props.report.status?.status ?? ReportStatusEnum.COMPLETE,
              deviatedAnswersCount: deviatedAnswers.length,
              comment: props.comment,
              placeName: props.report.place.name ?? '',
            }}
          />
          <Grid borderLeft borderRight borderBottom>
            <Col>
              <Text style={{ ...styles.textPadding }}>コメント</Text>
              <BreakText text={props.comment} />
            </Col>
          </Grid>
          {showApprovals(
            props.report.approvalFlows,
            props.report.status?.status ?? ReportStatusEnum.COMPLETE,
          ) && (
            <Approvals
              approvals={props.report.approvals}
              approvalFlows={props.report.approvalFlows ?? []}
            />
          )}
        </Page>
      )}
      {props.report.isShowScore && <Scoring reportResult={props.report} />}
      {deviatedAnswers.length > 0 && (
        <Page size="A4" style={styles.body}>
          <DeviatedAnswers
            deviatedAnswers={deviatedAnswers}
            nodes={props.report.nodes}
          />
        </Page>
      )}
      {props.getPages().map((page: ReportPageSchema) => (
        <Page key={page.id} size="A4" style={styles.body}>
          <ReportSummaryPage page={page} nodes={props.report.nodes} />
        </Page>
      ))}
      {props.report.images.length > 0 &&
        props.report.manualMemos.length > 0 && (
          <Page size="A4" style={styles.body}>
            <ReportSummaryOptions
              images={props.report.images}
              manualMemos={props.report.manualMemos}
            />
          </Page>
        )}
    </Document>
  )
}

export default ReportSummaryDocumentContainer
