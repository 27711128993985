import {
  ScheduledReportDailyStatus,
  ScheduledReportSummariesWithDaysInfo,
} from 'src/features/scheduledReportSummary/types'

const humanReadableStatus: { [key in ScheduledReportDailyStatus]: string } = {
  implemented: '実施済み',
  implementedAfterDeadline: '期限切れで実施',
  implementing: '記録中',
  notScheduled: '予定なし',
  notImplementedYet: '未実施',
  notStarted: '開始前',
}

/**
 * 実施状況のデータを人間が読みやすいTSVに変換する
 */
export const toFriendlyTsv = (
  data: ScheduledReportSummariesWithDaysInfo,
): string => {
  let result = ''

  // ヘッダー行
  result = ['現場名', 'スケジュール名', ...data.targetDates].join('\t') + '\n'

  data.summaries.forEach(summary => {
    const row = [
      summary.placeName,
      summary.scheduleName,
      ...summary.dailyDetails.map(detail => humanReadableStatus[detail.status]),
    ].join('\t')

    result += row + '\n'
  })

  return result
}
