import {
  AddUserGroupRequest,
  AddUserRequest,
  DeleteUserGroupRequest,
  DeleteUserRequest,
  ErrorCodeEnum,
  GetUserGroupsRequest,
  GetUserGroupsResponse,
  GetUserRequest,
  GetUsersResponse,
  GetUsersV2Request,
  UpdateUserGroupRequest,
  UpdateUserRequest,
  User,
  UsersApi,
  ValidateDeleteUserGroupRequest,
  ValidateDeleteUserRequest,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import * as notificationServiceActions from 'src/features/notificationService/slice'
import BaseClient from '../../middleware/saga/baseClient'
import {
  HTTPError,
  handleHTTPError,
} from '../../middleware/saga/handleHttpError'
import interceptionsActions from '../interceptions/actions'
import { default as actions } from './actions'
import { ActionTypes } from './types'

const baseClient = new BaseClient()

const getUsersV2Request = (req: GetUsersV2Request) => {
  return baseClient
    .getApi(UsersApi)
    .getUsersV2(req)
    .then(users => users)
    .catch(handleHTTPError)
}

const addUserRequest = (req: AddUserRequest) => {
  return baseClient
    .getApi(UsersApi)
    .addUser(req)
    .then(user => user)
    .catch(handleHTTPError)
}

const updateUserRequest = (req: UpdateUserRequest) => {
  return baseClient
    .getApi(UsersApi)
    .updateUser(req)
    .then(user => user)
    .catch(handleHTTPError)
}

const deleteUserRequest = (req: DeleteUserRequest) => {
  return baseClient.getApi(UsersApi).deleteUser(req).catch(handleHTTPError)
}

const validateDeleteUserRequest = (req: ValidateDeleteUserRequest) => {
  return baseClient
    .getApi(UsersApi)
    .validateDeleteUser(req)
    .catch(handleHTTPError)
}

const getUserGroupsRequest = (req: GetUserGroupsRequest) => {
  return baseClient
    .getApi(UsersApi)
    .getUserGroups(req)
    .then(userGroups => userGroups)
    .catch(handleHTTPError)
}

const addUserGroupRequest = (req: AddUserGroupRequest) => {
  return baseClient
    .getApi(UsersApi)
    .addUserGroup(req)
    .then(userGroup => userGroup)
    .catch(handleHTTPError)
}

const updateUserGroupRequest = (req: UpdateUserGroupRequest) => {
  return baseClient
    .getApi(UsersApi)
    .updateUserGroup(req)
    .then(userGroup => userGroup)
    .catch(handleHTTPError)
}

const deleteUserGroupRequest = (req: DeleteUserGroupRequest) => {
  return baseClient.getApi(UsersApi).deleteUserGroup(req).catch(handleHTTPError)
}

const validateDeleteUserGroupRequest = (
  req: ValidateDeleteUserGroupRequest,
) => {
  return baseClient
    .getApi(UsersApi)
    .validateDeleteUserGroup(req)
    .catch(handleHTTPError)
}

const getUserRequest = (req: GetUserRequest) => {
  return baseClient.getApi(UsersApi).getUser(req).catch(handleHTTPError)
}

function* getUsers(action: ReturnType<typeof actions.getUsers>) {
  try {
    const params: GetUsersV2Request = {
      ...action.request,
      userFilter: { ...action.filter },
    }
    const response: GetUsersResponse = yield call(getUsersV2Request, params)
    yield put(actions.getSuccessUsers(response))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorUsers(error as Error))
  }
}

function* addUser(action: ReturnType<typeof actions.addUser>) {
  try {
    const user: User = yield call(addUserRequest, { user: action.user })
    yield put(actions.addSuccessUser(user))
    yield put(actions.changeIsShowEditUserDrawer(false))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorUser(error as Error))
  }
}

function* updateUser(action: ReturnType<typeof actions.updateUser>) {
  try {
    const user: User = yield call(updateUserRequest, {
      userId: action.userId,
      updateUser: {
        ...action.user,
        uuid: action.userId,
        currentPassword: action.currentPassword,
      },
    })
    yield put(actions.updateSuccessUser(user))

    const currentUserId = localStorage.getItem(localStorageKeys.loginUserUuid)
    if (currentUserId === action.userId) {
      localStorage.setItem(localStorageKeys.loginUserName, user.name)
      localStorage.setItem(
        localStorageKeys.loginUserRole,
        user.role?.role?.toString() || '',
      )
      localStorage.setItem(
        localStorageKeys.loginCompanyId,
        user.company?.id?.toString(),
      )
      localStorage.removeItem(localStorageKeys.listFilter)
    }

    yield put(actions.changeIsShowEditUserDrawer(false))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.updateErrorUser(error as Error))
  }
}

function* deleteUser(action: ReturnType<typeof actions.deleteUser>) {
  try {
    yield call(deleteUserRequest, { userId: action.userId })
    yield put(actions.deleteSuccessUser(action.userId))
    yield put(
      notificationServiceActions.showNotification({
        message: 'ユーザーを削除しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.deleteErrorUser(error as Error))
  }
}

function* validateDeleteUser(
  action: ReturnType<typeof actions.validateDeleteUser>,
) {
  try {
    yield call(validateDeleteUserRequest, { userUUID: action.userUUID })
    yield put(actions.validateDeleteUserSuccess())
  } catch (error) {
    const httpError: HTTPError = error as HTTPError
    if (
      httpError.status === 400 &&
      httpError.code === ErrorCodeEnum.CannotDeleteUserByApprovalError
    ) {
      // ユーザー削除前のバリデーションチェックで引っかかった場合はユーザー一覧画面でModalを表示したいので個別処理している
      yield put(
        actions.userDeleteValidateErrorMessage(httpError.errors.join('\n')),
      )
    } else {
      yield put(interceptionsActions.handleHttpError(error as HTTPError))
      yield put(actions.validateDeleteUserError(error as Error))
    }
  }
}

function* getUserGroups(action: ReturnType<typeof actions.getUserGroups>) {
  try {
    const nodes: GetUserGroupsResponse = yield call(
      getUserGroupsRequest,
      action.request,
    )
    yield put(actions.getSuccessUserGroups(nodes))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorUserGroups(error as Error))
  }
}

function* addUserGroup(action: ReturnType<typeof actions.addUserGroup>) {
  try {
    const nodes: GetUserGroupsResponse = yield call(
      addUserGroupRequest,
      action.request,
    )
    yield put(actions.getSuccessUserGroups(nodes))
    yield put(actions.changeIsShowEditUserGroupDrawer(false))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.addErrorUserGroup(error as Error))
  }
}

function* updateUserGroup(action: ReturnType<typeof actions.updateUserGroup>) {
  try {
    yield call(updateUserGroupRequest, {
      userGroupId: action.userGroupId,
      userGroup: action.userGroup,
    })
    yield put(actions.getUserGroups({}))
    yield put(actions.updateSuccessUserGroup())
    yield put(actions.changeIsShowEditUserGroupDrawer(false))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.updateErrorUserGroup(error as Error))
  }
}

function* deleteUserGroup(action: ReturnType<typeof actions.deleteUserGroup>) {
  try {
    yield call(deleteUserGroupRequest, { userGroupId: action.userGroupId })
    yield put(actions.getUserGroups({}))
    yield put(actions.deleteSuccessUserGroup(action.userGroupId))
    yield put(
      notificationServiceActions.showNotification({
        message: 'ユーザーグループを削除しました',
      }),
    )
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.deleteErrorUserGroup(error as Error))
  }
}

function* validateDeleteUserGroup(
  action: ReturnType<typeof actions.validateDeleteUserGroup>,
) {
  try {
    yield call(validateDeleteUserGroupRequest, {
      userGroupId: action.userGroupId,
    })
    yield put(actions.validateDeleteUserGroupSuccess())
  } catch (error) {
    const httpError: HTTPError = error as HTTPError
    if (
      httpError.status === 400 &&
      httpError.code === ErrorCodeEnum.CannotDeleteUserGroupByImproveError
    ) {
      // ユーザーグループ削除前のバリデーションチェックで引っかかった場合はユーザーグループ一覧画面でModalを表示したいので個別処理している
      yield put(
        actions.userGroupDeleteValidateErrorMessage(
          httpError.errors.join('\n'),
        ),
      )
    } else {
      yield put(interceptionsActions.handleHttpError(error as HTTPError))
      yield put(actions.validateDeleteUserError(error as Error))
    }
  }
}

function* getUser(action: ReturnType<typeof actions.getUser>) {
  try {
    const user: User = yield call(getUserRequest, { userId: action.userId })
    yield put(actions.getSuccessUser(user))
  } catch (error) {
    yield put(interceptionsActions.handleHttpError(error as HTTPError))
    yield put(actions.getErrorUser(error as Error))
  }
}

const sagas = [
  takeEvery(ActionTypes.REQUEST_GET_USERS, getUsers),
  takeEvery(ActionTypes.REQUEST_CHANGE_USERS_PAGE, getUsers),
  takeEvery(ActionTypes.REQUEST_CHANGE_USERS_SIZE, getUsers),
  takeEvery(ActionTypes.REQUEST_ADD_USER, addUser),
  takeEvery(ActionTypes.REQUEST_UPDATE_USER, updateUser),
  takeEvery(ActionTypes.REQUEST_DELETE_USER, deleteUser),
  takeEvery(ActionTypes.REQUEST_GET_USER_GROUPS, getUserGroups),
  takeEvery(ActionTypes.REQUEST_CHANGE_USER_GROUPS_PAGE, getUserGroups),
  takeEvery(ActionTypes.REQUEST_CHANGE_USER_GROUPS_SIZE, getUserGroups),
  takeEvery(ActionTypes.REQUEST_ADD_USER_GROUP, addUserGroup),
  takeEvery(ActionTypes.REQUEST_UPDATE_USER_GROUP, updateUserGroup),
  takeEvery(ActionTypes.REQUEST_DELETE_USER_GROUP, deleteUserGroup),
  takeEvery(ActionTypes.REQUEST_GET_USER, getUser),
  takeEvery(ActionTypes.REQUEST_VALIDATE_DELETE_USER, validateDeleteUser),
  takeEvery(
    ActionTypes.REQUEST_VALIDATE_DELETE_USER_GROUP,
    validateDeleteUserGroup,
  ),
]

export default sagas
