import { LayoutOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import {
  ResponseFormulaTokenTypeEnum,
  ResponseTypeEnum,
  ResponseURL,
  TemplateHint,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
  TemplatePageSchema,
} from '@ulysses-inc/harami_api_client'
import { Breadcrumb, Button, Layout } from 'antd'
import { Location } from 'history'
import { Dispatch, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Prompt, useLocation } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import Loading from 'src/components/loading/Loading'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import DataUnavailableWidget from 'src/features/dataUnavailableWidget/DataUnavailableWidget'
import { useDataUnavailableWidget } from 'src/features/dataUnavailableWidget/useDataUnavailableWidget'
import { extractUniqueMultipleChoiceSets } from 'src/features/templateEdit/extractUniqueMultipleChoiceSets'
import EditTemplateOption from 'src/features/templateEdit/templateOptions/EditTemplateOption'
import EditTemplatePages from 'src/features/templateEdit/templatePages/EditTemplatePages'
import { useSourceTemplateIdParam } from 'src/features/templateEdit/useSourceTemplateIdParam'
import { validatePageHasQuestion } from 'src/features/templateEdit/validate'
import { getMultipleChoiceSets } from 'src/state/ducks/multipleChoiceSets/actions'
import {
  getTemplatePages,
  resetTemplatePages,
  setTemplatePageErrorMessage,
} from 'src/state/ducks/templates/actions'
import {
  addTemplate,
  getEmptyTemplate,
  getPreparedTemplate,
  getTemplate,
  resetTemplate,
  setTemplateNameErrorMessage,
  updateTemplate,
} from 'src/state/ducks/templates/editTemplate/actions'
import { EditTemplateState } from 'src/state/ducks/templates/editTemplate/reducer'
import { getMultipleChoices } from 'src/state/ducks/templates/responseMultipleChoices/actions'
import {
  getTemplateHints,
  resetTemplateHints,
  setTemplateHintErrorMessage,
} from 'src/state/ducks/templates/templateHints/actions'
import { TemplatePagesState } from 'src/state/ducks/templates/templatePages/reducer'
import { getUserGroups } from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'

// このコンポーネントが使われる場面 (通常の新規作成 or ひな形テンプレートを使った新規作成 or 既存ひな形の編集)
type TemplateEditMode = 'new' | 'newFromPreparedTemplate' | 'edit'

const validate = (
  dispatch: Dispatch<any>,
  templateData: EditTemplateState,
  templateHints: TemplateHint[],
  templatePagesData: TemplatePagesState,
): boolean => {
  let isValidated = true
  if (templateData.name === '') {
    dispatch(setTemplateNameErrorMessage('ひな形名を入力してください'))
    isValidated = false
  }

  const templateNodes: TemplateNodeSchema[] = Object.values(
    templatePagesData.templateNodes,
  )
  const templatePages: TemplatePageSchema[] = Object.values(
    templatePagesData.templatePages,
  )

  // ヒント列が存在していてヒント名が未入力の場合はエラーにする
  const hintsExist = templateHints.length !== 0
  const allHintsHaveName = templateHints.every(hint => hint.name !== '')
  if (hintsExist && !allHintsHaveName) {
    dispatch(setTemplateHintErrorMessage('ヒント名を入力してください'))
    isValidated = false
  } else {
    dispatch(setTemplateHintErrorMessage(''))
  }

  // 回答項目が未選択の場合はエラーにする
  // {@link https://kaminashi.atlassian.net/browse/INE-1222}
  for (const node of Object.values(templateNodes)) {
    if (node.type === TemplateNodeTypeEnum.Question) {
      // node.question.responseTypeは0が入り得る（型を信頼できない）ので対処
      if (
        node.question?.responseType == null ||
        (node.question.responseType as ResponseTypeEnum | 0) === 0
      ) {
        dispatch(
          setTemplatePageErrorMessage(
            `質問名：「${node.question?.name}」の回答項目が設定されていません`,
          ),
        )
        isValidated = false
        break
      }
    }
  }

  if (templateData.isExcelConversion) {
    const isSetExcelConversionLabel = templateNodes.some(templateNode => {
      if (templateNode.type === TemplateNodeTypeEnum.Question) {
        return (templateNode?.question?.excelConversionTypes ?? []).length > 0
      }
      return false
    })
    if (!isSetExcelConversionLabel) {
      dispatch(
        setTemplatePageErrorMessage(
          '少なくとも1つEXCEL出力ラベルを設定してください',
        ),
      )
      isValidated = false
    }
  }

  if (templatePagesData.templatePageIds.length === 0) {
    dispatch(
      setTemplatePageErrorMessage('少なくとも1つページを設定してください'),
    )
    isValidated = false
  }

  // ページ毎に1つ以上質問がない場合はエラーにする
  const everyPageHasSomeQuestion = validatePageHasQuestion(
    templatePagesData.templateNodes,
    templatePagesData.templatePages,
  )
  if (!everyPageHasSomeQuestion) {
    dispatch(setTemplatePageErrorMessage('少なくとも1つ質問を設定してください'))
    isValidated = false
  }

  // インフォメーション＆必須で画像が未設定の場合はエラーにする
  const informationNode = templateNodes.find(
    (node: TemplateNodeSchema) =>
      node.question?.responseType === ResponseTypeEnum.INFORMATION &&
      node.question?.isRequired === 1 &&
      (node.question?.responseInformations ?? []).length === 0,
  )
  if (informationNode) {
    dispatch(
      setTemplatePageErrorMessage(
        `質問名：「${informationNode.question?.name}」の画像が設定されていません`,
      ),
    )
    isValidated = false
  }
  const getProtocol = (text: string): string | undefined => {
    try {
      return new URL(text).protocol
    } catch {
      return undefined
    }
  }
  // ResponseURLのバリデーション
  const validateResponseURL = (
    responseURL: ResponseURL,
    questionName: string,
  ): string | null => {
    if (responseURL.url === '') {
      return `質問名：「${questionName}」のURLを入力してください`
    }
    const protocol = getProtocol(responseURL.url)
    if (protocol == undefined) {
      return `質問名：「${questionName}」のURL形式が正しくありません`
    }
    if (!['https:', 'http:'].includes(protocol)) {
      return `質問名：「${questionName}」にはhttpsもしくはhttpから始まるURLを入力してください`
    }
    const responseURLMaxLength = 2000
    if (responseURL.url.length > responseURLMaxLength) {
      return `質問名：「${questionName}」のURLは${responseURLMaxLength}文字以内で入力してください`
    }
    return null
  }
  templateNodes.forEach(node => {
    const isURLQuestion = node.question?.responseType === ResponseTypeEnum.URL
    if (!isURLQuestion) return
    const responseURL = node.question?.responseURLs?.[0]
    if (!responseURL) return

    const err = validateResponseURL(responseURL, node.question?.name ?? '')
    if (err !== null) {
      dispatch(setTemplatePageErrorMessage(err))
      isValidated = false
    }
  })

  const formulaNodes: TemplateNodeSchema[] = templateNodes.filter(
    (node: TemplateNodeSchema) =>
      node.question?.responseType === ResponseTypeEnum.FORMULA,
  )
  if (formulaNodes.length > 0) {
    formulaNodes.forEach(formulaNode => {
      const responseFormula = formulaNode.question?.responseFormulas?.[0]
      if (
        !responseFormula ||
        !responseFormula.tokens ||
        responseFormula.tokens.length === 0
      ) {
        dispatch(
          setTemplatePageErrorMessage(
            `質問名：「${formulaNode.question?.name}」の計算式が未設定です`,
          ),
        )
        isValidated = false
        return
      }
      const parentPage = templatePages.find(page =>
        page.nodes?.some(nodeId => nodeId === formulaNode.id),
      )
      let numberNodeUUIDs: (string | undefined)[] = []
      if (parentPage) {
        numberNodeUUIDs = templateNodes
          .filter(node => parentPage.nodes?.some(nodeId => nodeId === node.id))
          .filter(
            node => node.question?.responseType === ResponseTypeEnum.NUMBER,
          )
          .map(node => node.uuid)
      } else {
        const parentNode = templateNodes.find(node =>
          node.nodes.some(nodeId => nodeId === formulaNode.id),
        )
        if (!parentNode) {
          return
        }
        numberNodeUUIDs = templateNodes
          .filter(node => parentNode.nodes.some(nodeId => nodeId === node.id))
          .filter(
            node => node.question?.responseType === ResponseTypeEnum.NUMBER,
          )
          .map(node => node.uuid)
      }
      const questionTokens = responseFormula.tokens.filter(
        token => token.type === ResponseFormulaTokenTypeEnum.QUESTION,
      )
      const illegalTokens = questionTokens.filter(
        token =>
          token.questionNodeUUID === undefined ||
          !numberNodeUUIDs.includes(token.questionNodeUUID),
      )
      if (
        responseFormula.tokens.length > 1 &&
        questionTokens.length > 0 &&
        illegalTokens.length > 0
      ) {
        dispatch(
          setTemplatePageErrorMessage(
            `質問名：「${formulaNode.question?.name}」の計算式が不正です`,
          ),
        )
        isValidated = false
      }
    })
  }

  return isValidated
}

export const EditTemplate = () => {
  const dispatch = useDispatch()

  const {
    editTemplate: templateData, // ひな形関係
    templatePages: templatePagesData, // ひな形ページ関係
    templateHints: templateHintsData, // ひな形ヒント関係
  } = useSelector((state: RootState) => state.templatesState)

  const isDirty = useSelector((state: RootState) => {
    return (
      state.templatesState.editTemplate.isDirty ||
      state.templatesState.templatePages.isDirty ||
      state.templatesState.templateHints.isDirty
    )
  })

  // 新規作成時にはURLパラメータにidという値は存在しないため、0になる
  const sourceTemplateId = useSourceTemplateIdParam()

  const location = useLocation()
  // ひな形テンプレートをもとにした新規作成かどうかを表すフラグ
  const isIndustry = location.search.includes('industry')

  const templateEditMode = useMemo((): TemplateEditMode => {
    switch (true) {
      case sourceTemplateId === 0: {
        return 'new'
      }
      case isIndustry: {
        return 'newFromPreparedTemplate'
      }
      default: {
        return 'edit'
      }
    }
  }, [sourceTemplateId, isIndustry])

  const dataUnavailableWidget = useDataUnavailableWidget()

  useEffect(() => {
    switch (templateEditMode) {
      case 'new': {
        dispatch(getEmptyTemplate())
        break
      }
      case 'newFromPreparedTemplate': {
        dispatch(getPreparedTemplate(sourceTemplateId))
        break
      }
      case 'edit': {
        dispatch(getTemplatePages(sourceTemplateId))
        dispatch(getTemplateHints(sourceTemplateId))
        dispatch(getTemplate(sourceTemplateId))
        break
      }
    }
    dispatch(getUserGroups({}))
    dispatch(getMultipleChoices())
    dispatch(getMultipleChoiceSets({}))
    return () => {
      dispatch(resetTemplate())
      dispatch(resetTemplatePages())
      dispatch(resetTemplateHints())
      dataUnavailableWidget.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const overlayTip =
    templateEditMode === 'edit'
      ? 'ひな形を更新中です...'
      : 'ひな形を登録中です...'

  const saveTemplate = useCallback(
    (
      templateData: EditTemplateState,
      templateHints: TemplateHint[],
      templatePagesData: TemplatePagesState,
    ) => {
      if (!validate(dispatch, templateData, templateHints, templatePagesData))
        return

      const multipleChoiceSets = extractUniqueMultipleChoiceSets(
        templatePagesData.templateNodes,
      )
      const templateArgs = {
        templatePages: templatePagesData.templatePages,
        templateNodes: templatePagesData.templateNodes,
        templatePageIds: templatePagesData.templatePageIds,
        templateHints: templateHints,
        name: templateData.name,
        isShowScore: templateData.isShowScore,
        isEditable: templateData.isEditable,
        isExcelConversion: templateData.isExcelConversion,
        isHideQuestionOptions: templateData.isHideQuestionOptions,
        isAudit: templateData.isAudit,
        isKeyboard: templateData.isKeyboard,
        layoutType: templateData.layoutType,
        hasVariables: templateData.hasVariables,
        manuals: templateData.manuals,
        icons: templateData.icons,
        approvalFlowId: templateData.approvalFlowId,
        placeNodes: templateData.selectedPlaceNodes,
        multipleChoiceSets,
      }
      if (templateEditMode === 'edit') {
        dispatch(updateTemplate(templateArgs, sourceTemplateId))
        dispatch(setTemplatePageErrorMessage(''))
      } else {
        dispatch(addTemplate(templateArgs))
      }
    },
    [dispatch, sourceTemplateId, templateEditMode],
  )

  const messageCallback = useCallback((location: Location) => {
    const message = '変更した内容が破棄されますがよろしいですか？'

    // NOTE: キャンセルしたときにURLがもとに戻らないバグがある
    if (location.pathname === '/templates') {
      return JSON.stringify({
        message,
        state: {
          data: window.history.state,
          title: document.title,
          url: window.location.href,
        },
      })
    }
    return message
  }, [])

  return (
    <>
      <LoadingOverlay
        tip={overlayTip}
        spinning={templateData.isLoading}
        size="large"
        render={
          <>
            <Header
              rightSlot={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => {
                    saveTemplate(
                      templateData,
                      templateHintsData.templateHints,
                      templatePagesData,
                    )
                  }}
                  disabled={dataUnavailableWidget.isVisible}
                >
                  保存
                </Button>,
              ]}
              sticky
            >
              <Breadcrumb css={styles.breadcrumb}>
                <Breadcrumb.Item>
                  <Link to="/templates">
                    <LayoutOutlined css={styles.layoutIcon} />
                    ひな形一覧
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  ひな形{templateEditMode === 'edit' ? 'の変更' : 'の追加'}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Header>
            {dataUnavailableWidget.isVisible ? (
              <DataUnavailableWidget />
            ) : (
              <Layout className="layout">
                <Prompt when={isDirty} message={messageCallback} />
                <Layout.Content>
                  {templateHintsData.isLoading || templateData.isLoading ? (
                    <Loading />
                  ) : (
                    <EditTemplateOption />
                  )}
                  {templatePagesData.isLoading ? (
                    <Loading />
                  ) : (
                    <EditTemplatePages />
                  )}
                </Layout.Content>
              </Layout>
            )}
          </>
        }
      />
    </>
  )
}

const styles = {
  breadcrumb: css`
    font-weight: bold;
  `,
  layoutIcon: css`
    margin-right: 5px;
  `,
}
