import { Typography } from 'antd'
import { ChangeEvent, FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  setTemplateNameErrorMessage,
  updateTemplateName,
} from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import {
  BasicRow,
  ErrorMessageText,
  FormLabel,
  NameInput,
  RequiedLabel,
} from './EditTemplateOption.styled'

const { Text } = Typography

const NameRow: FC = () => {
  const dispatch = useDispatch()
  const templateName = useSelector(
    (state: RootState) => state.templatesState.editTemplate.name,
    shallowEqual,
  )
  const templateNameErrorMessage = useSelector(
    (state: RootState) =>
      state.templatesState.editTemplate.templateNameErrorMessage,
    shallowEqual,
  )

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const templateName = e.target.value
    dispatch(updateTemplateName(templateName))
    dispatch(setTemplateNameErrorMessage(''))
  }

  return (
    <BasicRow justify="space-between">
      <FormLabel>
        <Text strong>ひな形名</Text>
      </FormLabel>
      <NameInput
        aria-label="ひな形名"
        onChange={onChange}
        value={templateName}
      />
      <RequiedLabel>※必須入力</RequiedLabel>
      {templateNameErrorMessage !== '' && (
        <ErrorMessageText>{templateNameErrorMessage}</ErrorMessageText>
      )}
    </BasicRow>
  )
}

export default NameRow
