import { Modal } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from 'src/features/auth/authExpiration/slice'
import { useAuthProcess } from 'src/features/auth/useAuthProcess'
import { RootState } from 'src/state/store'

export const AuthExpiredDialog = () => {
  const dispatch = useDispatch()
  const { cleanupAndPushOutUser } = useAuthProcess()

  const { isAuthExpired } = useSelector(
    (state: RootState) => state.authExpirationState,
  )

  useEffect(() => {
    if (isAuthExpired) {
      Modal.info({
        title: '認証期限切れ',
        content: '認証期限切れのため、ログイン画面へ遷移します',
        onOk() {
          // NOTE: 先に送られたAPIリクエストの結果が401で返ってきた場合に isAuthExpired が true になる
          // なので、もう一度サーバーに問い合わせてログアウト処理を行う必要はない
          cleanupAndPushOutUser({ serverRequest: false })
          dispatch(actions.reset())
        },
      })
    }
  }, [cleanupAndPushOutUser, dispatch, isAuthExpired])

  // hooksで実装してもよいのだが、配置場所が特殊なため、
  // あえてコンポーネントにして取り回しを良くしたもの
  return null
}
