import * as ChannelService from '@channel.io/channel-web-sdk-loader'
import { User } from '@ulysses-inc/harami_api_client'
import { useEffect } from 'react'

// スクリプトのロード自体はSPA起動時に行っておく
// https://developers.channel.io/docs/web-quickstart#step-2-install-the-sdk
ChannelService.loadScript()

type Props = {
  pluginKey: string
  user: User
  userRoleName: string
}

export const ChannelTalkLauncher = (props: Props) => {
  const { pluginKey, user, userRoleName } = props

  useEffect(() => {
    const userCreatedAt = Date.parse(user.createdAt ?? '')
    ChannelService.boot({
      pluginKey,
      memberId: user.uuid,
      profile: {
        name: user.company.name + ': ' + user.name,
        email: user.email ?? '',
        userCreatedAt: isNaN(userCreatedAt) ? null : userCreatedAt,
        companyId: user.company.id,
        startContractDate: user.company.startContractDate?.getTime() || null,
        env: import.meta.env['VITE_APP_ENV'],
        contractPlan: user.company.contractPlan,
        loginId: user.loginId,
        role: userRoleName,
        webAppVersion: import.meta.env['VITE_APP_GIT_COMMIT_SHA'],
      },
      language: 'ja',
    })

    return () => {
      // ログアウト時を想定したもの
      ChannelService.shutdown()
    }
  }, [pluginKey, user, userRoleName])

  return null
}
