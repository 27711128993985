import { createSlice } from '@reduxjs/toolkit'

interface AuthExpirationState {
  isAuthExpired: boolean
}

const initialState: AuthExpirationState = {
  isAuthExpired: false,
}

export const authExpirationSlice = createSlice({
  name: 'harami/authExpiration',
  initialState,
  reducers: {
    expire: state => {
      state.isAuthExpired = true
    },
    reset: () => initialState,
  },
})

export const { expire, reset } = authExpirationSlice.actions
export default authExpirationSlice.reducer
