import { HomeOutlined, TagOutlined } from '@ant-design/icons'
import { AppRoleEnum, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Divider } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeIsShowEditUserDrawer,
  changeUpdatedUsersFlag,
} from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'
import FooterButton from 'src/views/components/drawer/FooterButton'
import {
  EditFormSideBySideRow,
  EditFormSideBySideWrap,
  UserPageHeader,
  getRenderInput,
  getRenderSelect,
} from './EditUser.components'
import { useFormikProps } from './EditUser.hooks'

export interface UserForm {
  id?: number
  uuid?: string
  givenName: string
  familyName: string
  givenNameKana?: string
  familyNameKana?: string
  loginId: string
  email: string
  password: string // ユーザー作成時のみ使用しているフィールド
  role: AppRoleEnum
  currentPassword?: string // ユーザー編集時のみ使用しているフィールド
  newPassword: string // ユーザー編集時のみ使用しているフィールド
  newPasswordConfirm?: string // ユーザー編集時のみ使用しているフィールド
  groupIds: string[]
  placeNodeIds: string[]
}

const roleOptions: [AppRoleEnum, string][] = [
  [AppRoleEnum.OWNER, '管理者'],
  [AppRoleEnum.EDITOR, '編集者'],
  [AppRoleEnum.LEADER, 'リーダー'],
  [AppRoleEnum.REPORTER, 'レポート作成者'],
]

const EditUser: React.FC = () => {
  const activeUser = useSelector(({ usersState }: RootState) => {
    const activeUserId = usersState.users.activeUserId
    return activeUserId
      ? usersState.users.users.find(({ uuid }) => uuid === activeUserId)
      : undefined
  })
  const userGroups = useSelector(
    ({ usersState }: RootState) => usersState.userGroups.userGroups,
  )
  const placeNodes = useSelector(
    ({ placesState }: RootState) => placesState.placeNodes.nodes,
  )
  const isLoadingUsers = useSelector(
    ({ usersState }: RootState) => usersState.users.isLoading,
  )

  const dispatch = useDispatch()
  const dispatchChangeIsShowEditUserDrawer = (isShow: boolean) =>
    dispatch(changeIsShowEditUserDrawer(isShow))
  const dispatchChangeUpdatedUsersFlag = (edited: boolean) =>
    dispatch(changeUpdatedUsersFlag(edited))

  const formikProps = useFormikProps(!!activeUser)

  const renderInput = getRenderInput(formikProps)
  const renderSelect = getRenderSelect(formikProps)

  type TParams = Parameters<typeof renderInput>
  const renderNameInput = (key: TParams[0], label: TParams[1]) =>
    renderInput(key, label, {
      defaultValue: activeUser?.name ?? '',
      Wrapper: EditFormSideBySideWrap,
    })
  const renderPasswordInput = (key: TParams[0], label: TParams[1]) =>
    renderInput(key, label, { isPassword: true })

  const { handleSubmit } = formikProps

  const onSubmit = () => {
    handleSubmit()
    dispatchChangeUpdatedUsersFlag(true)
  }

  return (
    <>
      <UserPageHeader>
        {activeUser ? 'ユーザーの編集' : 'ユーザーの追加'}
      </UserPageHeader>
      <EditFormSideBySideRow>
        {renderNameInput('familyName', '姓')}
        {renderNameInput('givenName', '名')}
      </EditFormSideBySideRow>
      <EditFormSideBySideRow>
        {renderNameInput('familyNameKana', '姓(フリガナ)')}
        {renderNameInput('givenNameKana', '名(フリガナ)')}
      </EditFormSideBySideRow>
      <Divider />
      {renderSelect(
        'role',
        '権限',
        roleOptions,
        activeUser?.role?.role ?? AppRoleEnum.REPORTER,
      )}
      <Divider />
      {renderInput('loginId', 'ログインID', {
        defaultValue: activeUser?.loginId ?? '',
      })}
      {renderInput('email', 'メールアドレス', {
        defaultValue: activeUser?.email ?? '',
      })}
      {activeUser ? (
        <>
          {renderPasswordInput('newPassword', '新しいパスワード')}
          {renderPasswordInput(
            'newPasswordConfirm',
            '新しいパスワード(確認用)',
          )}
          {renderPasswordInput('currentPassword', '現在のパスワード')}
        </>
      ) : (
        renderPasswordInput('password', 'パスワード')
      )}
      <Divider />
      {renderSelect(
        'groupIds',
        '所属するユーザーグループ',
        userGroups?.map(({ id, name }) => [`${id ?? ''}`, name ?? '']) ?? [],
        activeUser?.userGroups?.map(({ id }) => `${id ?? ''}`),
        true,
      )}
      {renderSelect(
        'placeNodeIds',
        '所属する現場',
        placeNodes.map(({ uuid, type, place, placeGroup }) => [
          uuid,
          type === PlaceNodeTypeEnum.PlaceGroup
            ? placeGroup?.name ?? ''
            : place?.name ?? '',
          type === PlaceNodeTypeEnum.PlaceGroup ? (
            <TagOutlined />
          ) : (
            <HomeOutlined />
          ),
        ]),
        activeUser?.placeNodes?.map(({ uuid }) => uuid),
        true,
      )}

      <FooterButton
        spinning={isLoadingUsers}
        onCancel={() => dispatchChangeIsShowEditUserDrawer(false)}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default EditUser
