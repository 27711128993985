import { PlusCircleOutlined } from '@ant-design/icons'
import { TemplateHint } from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { addTemplateHint } from 'src/state/ducks/templates/templateHints/actions'
import { RootState } from 'src/state/store'
import {
  BasicRowHint,
  ErrorMessageText,
  TableCollapse,
  TablePanel,
} from './EditTemplateOption.styled'
import HintTable from './HintTable'

const HintRow: FC = () => {
  const templateHints = useSelector(
    (state: RootState) => state.templatesState.templateHints.templateHints,
    shallowEqual,
  )
  const templateHintErrorMessage = useSelector(
    (state: RootState) =>
      state.templatesState.templateHints.templateHintErrorMessage,
    shallowEqual,
  )

  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(addTemplateHint())
  }

  return (
    <BasicRowHint>
      <TableCollapse bordered={false} accordion defaultActiveKey={[1]}>
        <TablePanel key={1} header={'ヒント列の追加'} data-testid="hint-table">
          <Button onClick={onClick} type="link">
            <PlusCircleOutlined />
            ヒント列を追加
          </Button>
          <HintTableWithError
            templateHints={templateHints}
            errMsg={templateHintErrorMessage}
          />
        </TablePanel>
      </TableCollapse>
    </BasicRowHint>
  )
}

const HintTableWithError: FC<{
  templateHints: TemplateHint[]
  errMsg: string
}> = ({ templateHints, errMsg }) => {
  if (templateHints.length === 0) {
    return <></>
  }

  return (
    <>
      <HintTable />
      {errMsg !== '' && <ErrorMessageText>{errMsg}</ErrorMessageText>}
    </>
  )
}

export default HintRow
