import { AppRoleEnum } from '@ulysses-inc/harami_api_client'
import { Input, Select, Typography } from 'antd'
import { FormikProps } from 'formik'
import { JSX, ReactNode } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import styled from 'styled-components'
import { UserForm } from './EditUser'

interface IRenderInputOptions {
  defaultValue?: UserForm[keyof UserForm]
  isPassword?: boolean
  Wrapper?: (props: { children: ReactNode }) => JSX.Element
}

const { Option } = Select

export const EditFormSideBySideRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const EditFormSideBySideWrap = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  width: 50%;
`

const EditFormControl = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
`

const EditFormLabel = styled(Typography.Text)`
  margin-left: 3px;
`

export const UserPageHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const getRenderInput = ({
  handleChange,
  values,
  errors,
  touched,
}: FormikProps<UserForm>) =>
  function EditFormControlInputWrapper(
    key: keyof UserForm,
    label: string,
    {
      defaultValue = '',
      isPassword = false,
      Wrapper = EditFormControl,
    }: IRenderInputOptions,
  ) {
    const InputComponent = isPassword ? Input.Password : Input

    return (
      <Wrapper>
        <EditFormLabel>{label}</EditFormLabel>
        <InputComponent
          onChange={handleChange(key)}
          defaultValue={defaultValue}
          value={values[key]}
        />
        <FormikErrorMessage name={key} errors={errors} touched={touched} />
      </Wrapper>
    )
  }

export const getRenderSelect = ({
  setFieldValue,
  errors,
  touched,
}: FormikProps<UserForm>) => {
  const filterOption = (inputValue: string, option: any) => {
    const optionText = option.children.props.children[1].props.children // アイコンを除いたテキストを取得する
    return optionText?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  }

  return function EditFormControlSelectWrapper(
    key: keyof UserForm,
    label: string,
    options: [number | string, string, ReactNode?][],
    defaultValue: string[] | AppRoleEnum = [],
    isMultiple?: boolean,
  ) {
    return (
      <EditFormControl>
        <EditFormLabel>{label}</EditFormLabel>
        <Select
          mode={isMultiple ? 'multiple' : undefined}
          style={{ width: '100%' }}
          optionFilterProp="children"
          filterOption={filterOption}
          onChange={v => setFieldValue(key, v)}
          defaultValue={defaultValue}
        >
          {options.map(([v, n, i]) => (
            <Option key={v} value={v} icon={i}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {i ? <div style={{ marginRight: 8 }}>{i}</div> : ''}
                <span>{n}</span>
              </div>
            </Option>
          ))}
        </Select>

        {!isMultiple && (
          <FormikErrorMessage name={key} errors={errors} touched={touched} />
        )}
      </EditFormControl>
    )
  }
}
